.nav-link {
  text-decoration: none;
}

.avatar-img {
  width: 100%;
  padding-top: 5em;
  padding-bottom: 5em;
}

.banner-text {
  background-color: black;
  border-radius: 10px;
  margin: 0 auto;
  opacity: .8;
  width: 75%;
}

.banner-text h1 {
  padding-top: 15px;
  color: white;
  font-size: 50px;
  font-weight: bold;
}

.banner-text h3 {
  color: white;
}

.banner-text h5 {
  color: whitesmoke;
}

.banner-text hr {
  border-top: 5px dotted white;
  margin: auto;
  width: 50%;
}

.banner-text p {
  color: white;
  font-size: 20px;
  padding: 1em;
}

.banner-text article {
  color: white;
  font-size: 18px;
  padding: 2em;
}

.footer {
  background: #360033;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #0b8793, #360033);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #0b8793, #360033); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
}

.header-color {
  background: #360033;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #0b8793, #360033);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #0b8793, #360033); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.landing-div {
  background: #360033;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #0b8793, #360033);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #0b8793, #360033); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
  z-index: 9999;
  height: 100%;
  width: 100%;
  flex: 1;
  margin: auto;
}

.landing-grid {
  background: #360033;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #0b8793, #360033);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #0b8793, #360033); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  
  height: 100%;
  left: 0;
  scroll-behavior: smooth;
  text-align: center;
  top: 0;
  width: 100%;
}

.social-links {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 297px;
}

.social-links i {
  color: white;
  font-size: 5em;
  padding: 8px;
}

/*Resume Page css*/

.resume-right-col {
  background: #27221F;
  color: white;
  padding: 2em;
}

/* Contact Page css*/

.contact-body {
  height: 100%;
  justify-content: center;
  margin: auto;
  padding-top: 2em;
  width: 100%;
}

.contact-grid {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  color: white;
  height: 100%;
  justify-content: center;
  margin-bottom: 20px;
  text-align: center;
  width: 80%;
}

.contact-grid h2 {
  color: white;
  font-family: 'Nunito Sans', sans-serif;
}

.contact-grid hr {
  border-top: 5px dotted white;
  margin: auto;
  width: 50%;
}

.contact-grid p {
  color: white;
  font-family: 'Oxygen', sans-serif;
}

.contact-grid a {
  color: white;
  font-family: 'Oxygen', sans-serif;
}


.contact-links i {
  color: white;
  font-size: 40px;
  padding-right: 5rem;
}

.social-links i {
  color: white;
  font-size: 5em;
  padding: 8px;
}

/* Projects Page */
.project-grid {
  justify-content: center;
  flex: 1;
  margin: auto;
}

.project-img {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 70%;
}

.project-div {
  background: #ffd89b;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #19547b, #ffd89b);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #19547b, #ffd89b); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  width: 100%; 
  margin: auto;
  padding-top: 1em;
  padding-bottom: 2em;
}


.zoom {
  transition: transform .2s; /* Animation */
  margin: 0 auto;
}

.zoom:hover {
  transform: scale(1.3); 
}

.zoom-s {
  border-radius: 20px;
  box-shadow: 10px 5px 5px #27221F;
  transition: transform .2s; /* Animation */
  margin: 0 auto;
}

.zoom-s:hover {
  transform: scale(1.1); 
}